import React, { useContext } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { BrinkContext } from "../context/BrinkContext"
import { containerMaxWidth, MEDIA_MIN_MEDIUM, MEDIUM } from "../../constants"
import Button from "../../components/ui/Button"
import Link from "../../components/Link"
import VideoBg from "../../video/bg.mp4"

const Wrapper = styled(BackgroundImage)`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: flex-end;
  margin-bottom: 0.5rem;
`

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: ${(p) => p.mobilePos || "center"};
  position: relative;
  padding-bottom: 12rem;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -200rem;
    bottom: 0;
    left: -200rem;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 50%
    );
  }

  ${MEDIA_MIN_MEDIUM} {
    padding-bottom: 0;
    align-items: ${(p) => p.deskPos || "flex-end"};
  }
`

const TextContainer = styled.div`
  margin: 10rem 3rem 3rem;
  max-width: 90rem;
  text-align: center;
  z-index: 1;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0 8rem;
    text-align: center;
  }
`

const Text = styled.h1`
  display: inline-block;
  color: white;
  padding: 0.4rem 2rem 0;
  font-weight: 400;
  font-size: 4rem;
  line-height: 4rem;
  text-transform: uppercase;
  margin: 0.5rem 0;
  letter-spacing: 0.2rem;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);

  ${MEDIA_MIN_MEDIUM} {
    font-size: 7rem;
    line-height: 7rem;
    padding: 0.4rem 0 0;
  }
`

const SubText = styled.p`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 2rem;
  font-size: 1.6rem;
  margin: 1.5rem 1rem 0.5rem 0;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2rem;
    padding: 0;
  }

  i {
    margin-left: 0.8rem;
    padding-top: 0.5rem;
  }
`

const CallToAction = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    margin: 0 auto;
  }
`

const CallToActionButton = styled(Button)`
  width: auto;
  height: 5rem;
  margin: 1.5rem auto;
  color: ${(p) => p.theme.colors.white};
  background: ${(p) => p.theme.colors.secondary};

  i {
    font-size: 2rem;
    margin-left: 1rem;
    padding-bottom: 0.2rem;
  }

  &:hover {
    color: ${(p) => p.theme.colors.secondary};
    background: ${(p) => p.theme.colors.white};
  }

  ${MEDIA_MIN_MEDIUM} {
    margin: 1.5rem 1rem 0.5rem 0;
  }
`

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  video {
    width: auto;
    height: 100vh;

    ${MEDIA_MIN_MEDIUM} {
      height: auto;
      width: 100%;
    }
  }

  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(222, 171, 163, 0.55);
  }
`

export default ({ data }) => {
  const { languageCode } = useContext(BrinkContext)
  const {
    mobileImage,
    mobileContentPosition,
    desktopContentPosition,
    desktopImage,
    linkUrl,
    title,
    subTitle,
    videoUrl,
    buttonText
  } = data

  const sources = [
    mobileImage.asset.fluid,
    {
      ...desktopImage.asset.fluid,
      media: `(min-width: ${MEDIUM}px)`
    }
  ]

  return (
    <Wrapper fluid={sources}>
      {videoUrl && (
        <VideoContainer>
          <video autoplay="autoplay" muted loop>
            <source src={VideoBg} type="video/mp4" />
          </video>
          <div />
        </VideoContainer>
      )}
      <Container
        desktopPos={desktopContentPosition}
        mobilePos={mobileContentPosition}
      >
        <TextContainer>
          <Text>{title[languageCode] || title.en}</Text>
          <CallToAction>
            <SubText>{subTitle[languageCode] || subTitle.en}</SubText>
            <Link to={linkUrl}>
              <CallToActionButton>
                {buttonText[languageCode] || buttonText.en}{" "}
                <i className="fal fa-long-arrow-right"></i>
              </CallToActionButton>
            </Link>
          </CallToAction>
        </TextContainer>
      </Container>
    </Wrapper>
  )
}
