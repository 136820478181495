import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { BrinkContext } from "../context/BrinkContext"
import {
  containerSmallMaxWidth,
  MEDIUM,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../../constants"
import Btn from "../ui/Button"

const Container = styled.div`
  width: 100%;
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto;
  padding: 0;
  position: relative;

  ${MEDIA_MIN_LARGE} {
    width: ${(p) =>
      p.columns
        ? `calc(100% / ${p.columns} - ${p.columns * 0.8}rem)`
        : "calc(100% / 2)"};

    &:not(:first-child):not(:last-child) {
      margin: 0 3rem 1.5rem;
    }
  }
`

const BackgroundImage = styled(Img)`
  width: 100%;
  margin-bottom: 0.2rem;
`

const ContentWrapper = styled.div`
  width: 100%;
  color: ${(p) => p.theme.colors.white};
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.05);
  transition: background 0.2s;

  &:hover {
    background: transparent;
  }
`

const Content = styled.div`
  text-align: center;
  padding: 4rem 3rem 3rem;
`

const Preamble = styled.p`
  margin: 0;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: 0.2rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.6rem;
  }
`

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 3rem;
  line-height: 3rem;
  z-index: 1;

  ${MEDIA_MIN_MEDIUM} {
    line-height: 4rem;
    font-size: 3.6rem;
  }
`

const SubTitle = styled.p`
  font-size: 1.4rem;
  margin: 0 auto 0;
  padding: 1.2rem 0 0.4rem;
  text-align: center;
`

const Button = styled(Btn)`
  display: inline-flex;
  color: ${(p) => p.theme.colors.white};
  border: 0.1rem solid;
  width: auto;
  margin-top: 2rem;

  &:hover {
    color: ${(p) => p.theme.colors.black};
    background: ${(p) => p.theme.colors.white};
    border: 0.1rem solid ${(p) => p.theme.colors.white};
  }
`

export default ({ data, columns }) => {
  const { languageCode } = useContext(BrinkContext)
  const {
    preamble,
    title,
    subTitle,
    linkUrl,
    buttonText,
    mobileBackgroundImage,
    desktopBackgroundImage
  } = data

  const sources = [
    mobileBackgroundImage.asset.fluid,
    {
      ...desktopBackgroundImage.asset.fluid,
      media: `(min-width: ${MEDIUM}px)`
    }
  ]

  return (
    <Container columns={columns}>
      <BackgroundImage fluid={sources} />
      <ContentWrapper>
        <Content>
          {preamble && (
            <Preamble>{preamble[languageCode] || preamble.en}</Preamble>
          )}
          {title.en && <Title>{title[languageCode] || title.en}</Title>}
          {subTitle.en && (
            <SubTitle>{subTitle[languageCode] || subTitle.en}</SubTitle>
          )}
          {linkUrl && (
            <Link to={linkUrl}>
              <Button>{buttonText[languageCode] || buttonText.en}</Button>
            </Link>
          )}
        </Content>
      </ContentWrapper>
    </Container>
  )
}
